import { Icon } from '@nike/eds';
import { ChangeEvent } from 'react';

import { useAppStore } from '../../state/store';

function Graphic() {
  const graphic = useAppStore(state => state.graphic);
  const setGraphic = useAppStore(state => state.setGraphic);
  const removeGraphic = useAppStore(state => state.removeGraphic);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);

      reader.onloadend = (readerEvent: ProgressEvent<FileReader>) => {
        if (readerEvent.target) {
          const fileContent = reader.result as string;
          const base64 = fileContent.split('base64,')[1];

          setGraphic(base64, readerEvent.target.result);
        }
      };
    }
  };

  return (
    <div>
      <div className="p-4 flex justify-center items-center border-dashed border border-eds-grey-2 rounded-md h-[206px] relative">
        {!graphic?.url ? (
          <div className="py-10 px-4 text-xs text-eds-grey-7 text-center">
            <Icon name="ImagenoImage" color="var(--eds-color-grey-1)" className="h-8 w-8" />

            <div className="justify-start leading-relaxed mt-2">
              <button type="button" className="inline cursor-pointer" onClick={() => '#'}>
                <label htmlFor="upload-control-app-panel" className="ml-1 text-xs cursor-pointer">
                  <span className="ml-1 text-eds-grey-1">
                    <span className="text-eds-blue-50">Click here</span> to upload the graphic that
                    will be applied to the shell.
                    <input
                      type="file"
                      id="upload-control-app-panel"
                      className="hidden"
                      accept="image/png"
                      onChange={handleFileChange}
                    />
                  </span>
                </label>
              </button>
            </div>
            <span className="block text-eds-grey-1 text-center leading-relaxed">
              Supported file type: PNG
            </span>
          </div>
        ) : (
          <>
            <div className="flex flex-col absolute top-3 right-3 w-8 h-8">
              <button
                type="button"
                onClick={() => '#'}
                className="shadow-eds-elevation-3 rounded-lg px-1 py-1 bg-white mb-2"
              >
                <label htmlFor="upload-control-app-panel" className="text-xs cursor-pointer">
                  <Icon name="Share" className="w-4" color="var(--eds-color-black)" />

                  <input
                    type="file"
                    id="upload-control-app-panel"
                    className="hidden"
                    accept="image/png"
                    onChange={handleFileChange}
                  />
                </label>
              </button>

              <button
                type="button"
                onClick={removeGraphic}
                className="shadow-eds-elevation-1 rounded-lg px-1 py-1 bg-white"
              >
                <Icon name="Delete" color="var(--eds-color-black)" size="m" className="px-1 py-1" />
              </button>
            </div>

            <img
              src={graphic.url as string}
              alt="graphic"
              className="max-h-[175px]"
              draggable={false}
            />
          </>
        )}
      </div>
    </div>
  );
}

export default Graphic;
