import { Button, ButtonGroup, Spinner, Tooltip, Snackbar, Snack } from '@nike/eds';
import { useState } from 'react';
import { ComposureClient } from '@nike.innovation/composure-sdk';
import { useOktaAuth } from '@okta/okta-react';

import { environment } from '../../../environments/environment';
import { useAppStore } from '../../state/store';
import { downloadBinaryFile, rebuildFile } from '../../utils/file-utils';
import { ga4Event } from '../../utils/ga4-events';

function AppToolbar() {
  const { oktaAuth } = useOktaAuth();
  const token = oktaAuth.getAccessToken();

  if (!token) {
    throw new Error('Error retrieving access token');
  }

  const composureClient = new ComposureClient(token, environment.composureEnvironment);

  const simRunning = useAppStore(state => state.isSimulationRunning);
  const setIsSimulationRunning = useAppStore(state => state.setIsSimulationRunning);

  const shell = useAppStore(state => state.shell);
  const bitmap = useAppStore(state => state.bitmap);
  const graphic = useAppStore(state => state.graphic);
  type ShowSnackBarType = 'success' | 'error' | null;

  const coursePx = useAppStore(state => state.coursePx);
  const courseIn = useAppStore(state => state.courseIn);
  const walePx = useAppStore(state => state.walePx);
  const waleIn = useAppStore(state => state.waleIn);
  const [showSnackBar, setShowSnackBar] = useState<ShowSnackBarType>(null);

  const simReady =
    shell !== undefined &&
    shell.anchors.length > 0 &&
    bitmap !== undefined &&
    bitmap.anchors.length > 0 &&
    bitmap.wedgeColors.length > 0 &&
    shell.anchors.length === bitmap.anchors.length &&
    bitmap.exclusionColors.length > 0 &&
    bitmap.graphicColors.length > 0 &&
    graphic !== undefined;

  // Format color string into an appropriate format for Rhino compute
  const formatColorString = (colorString: string): string =>
    colorString.slice(colorString.indexOf('(') + 1, colorString.indexOf(')'));

  // Runs the SVG to Curve GH script thru Composure-SDK and returns the SVG transformed as a Curve
  const svgToCurve = async (content: string): Promise<string> => {
    const key = `/${environment.svgToCurveScriptId}/versions/latest`;
    const body = {
      SVG: content,
    };
    try {
      const response = await composureClient.solve(key, body, true);
      if (response.ok) return response.val.Curves[0] as string;
      console.error(response.err);
      return '';
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  // Runs the Spiderweb simulation script thru Composure-SDK and downloads the output files to the client
  const handleRunSimulation = async () => {
    if (shell && bitmap && graphic) {
      const key = `/${environment.spiderwebScriptId}/versions/published`;
      setIsSimulationRunning(true);
      if (window.location.host === 'spiderweb.innovation.nike.io') {
        ga4Event({ category: 'simulation', action: 'run', label: 'success' });
      }

      try {
        // Build body
        const body = {
          bmp: bitmap.assetString,
          pt_shellAnchors: shell.exportPoints.map(
            anchor => `{"X": ${anchor.x}, "Y": ${anchor.y}, "Z": 0}`
          ),
          pt_bmpAnchors: bitmap.exportPoints.map(
            anchor => `{"X": ${anchor.x}, "Y": ${anchor.y}, "Z": 0}`
          ),
          crv_shell: await svgToCurve(shell.assetString),
          shell_design: graphic.assetString,
          graphic_zone_colors: bitmap.graphicColors.map(colors => formatColorString(colors)),
          exclude_colors: bitmap.exclusionColors.map(colors => formatColorString(colors)),
          wedge_colors: bitmap.wedgeColors.map(colors => formatColorString(colors)),
          run: true,
          course_px: coursePx,
          course_in: courseIn,
          wale_px: walePx,
          wale_in: waleIn,
        };
        const strippedfilenameShell = shell.fileName.split('.').slice(0, -1).join('.');
        const strippedfilenameBMP = bitmap.fileName.split('.').slice(0, -1).join('.');

        console.log('filename_shell', strippedfilenameShell);
        console.log('filename_bmp', strippedfilenameBMP);

        const responsePromise = composureClient.solve(key, body, true);

        const response = (await Promise.race([responsePromise])) as any;
        if (response.ok) {
          const newColoredBase64 = response.val.new_colored_bmp[0];
          const newShellGraphicBase64 = response.val.new_shell_graphic_bmp[0];

          if (!newColoredBase64 || !newShellGraphicBase64) {
            setShowSnackBar('error');
            console.log(response);
            throw new Error('Simulation incomplete');
          } else {
            setShowSnackBar('success');
            if (window.location.host === 'spiderweb.innovation.nike.io') {
              ga4Event({ category: 'simulation', action: 'result', label: 'success' });
            }
          }
          if (newColoredBase64)
            downloadBinaryFile(
              rebuildFile(newColoredBase64, 'image/bmp'),
              `${strippedfilenameBMP}-Spiderweb-BMP.bmp`
            );

          if (newShellGraphicBase64)
            downloadBinaryFile(
              rebuildFile(newShellGraphicBase64, 'image/bmp'),
              `${strippedfilenameShell}-Spiderweb-Shell.bmp`
            );

          setIsSimulationRunning(false);
        } else {
          console.error(response.err);
          setIsSimulationRunning(false);
          setShowSnackBar('error');
          if (window.location.host === 'spiderweb.innovation.nike.io') {
            ga4Event({ category: 'simulation', action: 'result', label: 'failure' });
          }
        }
      } catch (err) {
        console.error(err);
        setIsSimulationRunning(false);
        setShowSnackBar('error');
        if (window.location.host === 'spiderweb.innovation.nike.io') {
          ga4Event({ category: 'simulation', action: 'result', label: 'failure' });
        }
      }
    }
  };

  const activeTool = useAppStore(state => state.activeTool);
  const setActiveTool = useAppStore(state => state.setActiveTool);

  return (
    <div className="flex flex-row items-center justify-between h-16 max-h-16 px-4 py-1 border-b border-eds-grey-2">
      <ul className="flex flex-row max-h-10">
        <Tooltip bodySlot="Select & Pan" isDark>
          <li
            className={`
            mr-3 rounded-md
            ${
              activeTool === 'select'
                ? 'bg-eds-black text-white hover:none'
                : 'text-eds-black hover:bg-eds-grey-3 hover:text-eds-grey-1'
            }`}
          >
            <button
              type="button"
              className="h-10 w-10 flex justify-center items-center"
              onClick={() => setActiveTool('select')}
            >
              <svg
                width="40"
                height="41"
                viewBox="0 0 40 41"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M26.1637 22.4619C26.0931 22.6351 25.9213 22.7465 25.7343 22.7385L20.6393 22.5856L22.8228 27.6806C22.9225 27.9048 22.8214 28.1668 22.5979 28.2666C22.595 28.268 22.5928 28.2695 22.5899 28.2702L20.8139 29.0708C20.5898 29.1706 20.327 29.0701 20.2273 28.8459C20.2266 28.843 20.2251 28.8408 20.2244 28.8379L17.9898 23.6992L14.5689 27.5933C14.4081 27.7782 14.1271 27.7978 13.9422 27.6362C13.844 27.5511 13.7879 27.4259 13.7901 27.2949L13.8411 11.5513C13.8411 11.3737 13.9473 11.2136 14.1104 11.1437C14.2734 11.076 14.4612 11.1102 14.5907 11.231L26.0545 21.9669C26.1906 22.0929 26.2343 22.2901 26.1637 22.4619Z"
                  fill="currentColor"
                />
              </svg>
            </button>
          </li>
        </Tooltip>

        <Tooltip bodySlot="Add anchor point" isDark>
          <li
            className={`
            mr-3 rounded-md flex justify-center items-center
            ${
              activeTool === 'anchor'
                ? 'bg-eds-black text-white hover:none'
                : 'text-eds-black hover:bg-eds-grey-3 hover:text-eds-grey-1'
            }`}
          >
            <button
              type="button"
              className="h-10 w-10 flex justify-center items-center"
              onClick={() => setActiveTool('anchor')}
            >
              <svg
                width="19"
                height="20"
                viewBox="0 0 19 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.50352 11.8059C10.2767 11.8059 10.9035 11.1791 10.9035 10.4059C10.9035 9.63266 10.2767 9.00586 9.50352 9.00586C8.73032 9.00586 8.10352 9.63266 8.10352 10.4059C8.10352 11.1791 8.73032 11.8059 9.50352 11.8059Z"
                  fill="currentColor"
                />
                <path
                  d="M19.0039 9.65575H13.8359C13.5224 7.83175 12.0879 6.38775 10.2639 6.07425V0.90625H8.75341V6.08375C6.92941 6.39725 5.48541 7.84125 5.17191 9.65575H0.00390625V11.1663H5.18141C5.50441 12.9808 6.93891 14.4152 8.75341 14.7288V19.9062H10.2639V14.7288C12.0784 14.4152 13.5129 12.9808 13.8359 11.1663H19.0039V9.65575ZM9.50391 13.5887C7.74641 13.5887 6.32141 12.1637 6.32141 10.4062C6.32141 8.64875 7.74641 7.22375 9.50391 7.22375C11.2614 7.22375 12.6864 8.64875 12.6864 10.4062C12.6864 12.1637 11.2614 13.5887 9.50391 13.5887Z"
                  fill="currentColor"
                />
              </svg>
            </button>
          </li>
        </Tooltip>
      </ul>

      <div>
        <ButtonGroup>
          <Button
            onClick={() => {
              handleRunSimulation();
            }}
            variant="primary"
            className="bg-black text-md font-medium h-9 "
            disabled={!simReady || simRunning}
          >
            {simRunning ? <Spinner /> : 'Run Simulation'}
          </Button>
          {showSnackBar && (
            <Snackbar>
              <Snack
                id="1"
                onDismiss={() => setShowSnackBar(null)}
                status={showSnackBar === 'success' ? 'success' : 'error'}
                autoDismissDuration={8000}
                className="bottom-16 fixed"
              >
                {showSnackBar === 'success'
                  ? 'Simulation Complete - Downloaded files are ready to view'
                  : 'Simulation Incomplete - See Spiderweb Guide for more details'}
              </Snack>
            </Snackbar>
          )}
        </ButtonGroup>
      </div>
    </div>
  );
}

export default AppToolbar;
