/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-bitwise */
/* eslint-disable react/require-default-props */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/control-has-associated-label */
import { capitalize } from '@mui/material';
import { Button, ButtonGroup, Icon, Modal, Text, Tooltip } from '@nike/eds';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

import { ChangeEvent, useState, useRef, useEffect } from 'react';
import AnchorPoint from '../components/AnchorPoint';
import type { FileType } from '../types';
import { useAppStore } from '../../state/store';
import { Action, ImageAsset, WithAnchorPoints } from '../../state/api';
import { ga4Event } from '../../utils/ga4-events';

type EditorImageProps = {
  imageType: FileType;
  imageAsset?: ImageAsset & WithAnchorPoints;
  setImageAsset: Action['setShell'];
  removeImageAsset: Action['removeShell'];
  addAnchor: Action['addShellAnchor'];
  deleteAnchor: Action['deleteShellAnchor'];
};

function EditorImage({
  imageType,
  imageAsset,
  setImageAsset,
  removeImageAsset,
  addAnchor,
  deleteAnchor,
}: EditorImageProps) {
  const isShell = imageType === 'shell';

  const imageRef = useRef<HTMLImageElement>(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [hexcode, setHexcode] = useState<string>('');

  const activeTool = useAppStore(state => state.activeTool);
  const setActiveTool = useAppStore(state => state.setActiveTool);
  let previousTool: string | null = null;

  const handleDeleteImage = () => {
    if (imageAsset?.url !== null) {
      removeImageAsset();
    }

    setModalVisible(false);
    if (window.location.host === 'spiderweb.innovation.nike.io') {
      ga4Event({ category: isShell ? 'shell' : 'bitmap', action: 'delete', label: 'success' });
    }
  };

  // Image setup
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const uploadedFile = event.target.files[0];
      const fileName = uploadedFile.name; // This is the name of the file

      const reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]);
      reader.onloadend = (readerEvent: ProgressEvent<FileReader>) => {
        const fileContent = reader.result as string;
        const base64 = fileContent.split('base64,')[1];
        const text = atob(base64);

        if (readerEvent.target) {
          isShell
            ? setImageAsset(text, readerEvent.target.result, fileName)
            : setImageAsset(base64, readerEvent.target.result, fileName);

          if (window.location.host === 'spiderweb.innovation.nike.io') {
            ga4Event({ category: isShell ? 'shell' : 'bitmap', action: 'set', label: 'success' });
          }
        }
      };
    }
  };

  // Detecting 'backspace' key press for deleting selected anchor
  const handleKeyup = (event: KeyboardEvent) => {
    if (selectedIndex !== null && event.key === 'Backspace') deleteAnchor(selectedIndex);
    setSelectedIndex(null);
  };

  const handleClick = (event: React.MouseEvent) => {
    if (imageRef.current && imageRef.current !== null) {
      if (activeTool === 'anchor') {
        // Determine the clicked position on the image
        const image = imageRef.current;
        const imageRect = image.getBoundingClientRect();
        const imageScale = imageRect.width / image.width;
        const imageX = (event.clientX - imageRect.x) / imageScale;
        const imageY = (event.clientY - imageRect.y) / imageScale;
        const imageXPercentage = ((event.clientX - imageRect.x) / imageRect.width) * 100;
        const imageYPercentage = ((event.clientY - imageRect.y) / imageRect.height) * 100;

        // Determine the final position on the image.  For SVGS...
        // 1. Scale based on the difference between the webpage and the original SVG
        // 2. Convert from pixels to milimeters (Rhino assumes units are in mm)
        // 3. Invert y (because Adobe Illustrator inverts the y-axis)
        let exportX = imageX;
        let exportY = imageY;

        if (isShell && imageAsset?.assetString) {
          const viewBox = (/viewBox="([^"]+)"/.exec(imageAsset.assetString) || '')[1];
          if (viewBox) {
            const pixelsToMm = 0.352777778;
            const corners = viewBox.split(' ');
            const svgWidth = parseFloat(corners[2]);
            const svgHeight = parseFloat(corners[3]);
            exportX = imageX * (svgWidth / image.width) * pixelsToMm;
            exportY = -imageY * (svgHeight / image.height) * pixelsToMm;
          }
        }

        if (!isShell && imageAsset?.assetString) {
          const bitmap = document.getElementById('bitmap') as HTMLImageElement;
          if (bitmap) {
            exportX = imageX;
            exportY = bitmap.naturalHeight - imageY;
          }
        }

        addAnchor({ x: imageXPercentage, y: imageYPercentage }, { x: exportX, y: exportY });
      }
    }
  };

  const handleMouseMove = (event: React.MouseEvent) => {
    if (imageRef.current && imageRef.current !== null) {
      if (activeTool === 'select') {
        // Determine the clicked position on the image
        const image = imageRef.current;
        const imageRect = image.getBoundingClientRect();
        const imageScale = imageRect.width / image.width;
        const imageX = (event.clientX - imageRect.x) / imageScale;
        const imageY = (event.clientY - imageRect.y) / imageScale;

        // Determine the final position on the image.  For SVGS...
        // 1. Scale based on the difference between the webpage and the original SVG
        // 2. Convert from pixels to milimeters (Rhino assumes units are in mm)
        // 3. Invert y (because Adobe Illustrator inverts the y-axis)
        let exportX = imageX;
        let exportY = imageY;

        if (!isShell && imageAsset?.assetString) {
          const bitmap = document.getElementById('bitmap') as HTMLImageElement;
          if (bitmap) {
            exportX = imageX;
            exportY = bitmap.naturalHeight - imageY;
          }
        }

        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        if (context) {
          const bitmap = document.getElementById('bitmap') as HTMLImageElement;
          if (bitmap) {
            canvas.width = bitmap.naturalWidth;
            canvas.height = bitmap.naturalHeight;
            context.drawImage(bitmap, 0, 0);
            const pixelData = context.getImageData(imageX, imageY, 1, 1).data;
            const [r, g, b] = pixelData;

            const hex = `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
            setHexcode(hex);
          }
        }
      }
    }
  };

  useEffect(() => {
    document.addEventListener('keyup', handleKeyup);

    return () => {
      document.removeEventListener('keyup', handleKeyup);
    };
  }, [selectedIndex]);

  return (
    <>
      <Modal
        isOpen={modalVisible}
        onDismiss={() => setModalVisible(false)}
        headerSlot={<Text font="title-3">Delete {capitalize(imageType)}?</Text>}
        footerSlot={
          <ButtonGroup>
            <Button onClick={() => setModalVisible(false)} variant="secondary" size="small">
              Cancel
            </Button>

            <Button
              onClick={handleDeleteImage}
              variant="primary"
              size="small"
              className="bg-black text-md font-medium h-9"
            >
              Delete
            </Button>
          </ButtonGroup>
        }
      >
        <Text>
          The {imageType} and any corresponding anchor points will be deleted from the canvas.
        </Text>
      </Modal>
      {!imageAsset?.url ? (
        <div className="flex h-full w-full justify-center items-center">
          <input
            type="file"
            id={`upload-control-${imageType}`}
            className="hidden"
            accept={isShell ? 'image/svg+xml' : 'image/bmp'}
            onChange={handleFileChange}
          />
          <label
            htmlFor={`upload-control-${imageType}`}
            className="flex flex-col justify-center items-center cursor-pointer text-center"
          >
            <Icon name="Upload" size="l" color="var(--eds-color-grey-1)" />
            <span className="font-medium text-eds-blue-50">Click here</span>
            <div className="font-medium text-center text-eds-grey-7">
              to upload your {imageType}
            </div>
            <div className="font-sans font-medium text-eds-grey-1">
              Supported filetype: {imageType === 'bitmap' ? 'BMP' : 'SVG'}
            </div>
          </label>
        </div>
      ) : (
        <div
          className="relative h-full"
          style={{ cursor: activeTool === 'anchor' ? 'crosshair' : '' }}
          onMouseDown={event => {
            // Right mouse button was pressed, start panning
            if (event.button === 2) {
              if (activeTool === 'anchor') {
                previousTool = 'anchor';
              } else {
                previousTool = 'select';
              }
              setActiveTool('select');
            }
          }}
          onMouseUp={event => {
            // Mouse button was released, stop panning when right click released
            if (event.button === 2) {
              if (previousTool === 'select') {
                setActiveTool('select');
              } else {
                setActiveTool('anchor');
              }
              previousTool = null;
            }
          }}
          onContextMenu={event => {
            // Prevent the context menu from appearing
            event.preventDefault();
          }}
        >
          <TransformWrapper
            centerOnInit
            panning={{ disabled: activeTool === 'anchor', velocityDisabled: true }}
            maxScale={30}
            limitToBounds={false}
            wheel={{
              activationKeys: [],
              excluded: ['wheelDisabled'],
              smoothStep: 0.01,
              step: 0.1,
            }}
          >
            <TransformComponent wrapperStyle={{ height: '100%', width: '100%' }}>
              <img
                ref={imageRef}
                id={imageType}
                src={imageAsset.url as string}
                alt={isShell ? 'flyknit shell' : 'bitmap'}
                draggable={false}
              />
              {imageAsset.anchors.map((anchor, index) => (
                <AnchorPoint
                  key={`${anchor.x},${anchor.y}`}
                  pos={anchor}
                  index={index + 1}
                  selectedIndex={selectedIndex}
                  setSelectedIndex={setSelectedIndex}
                />
              ))}

              {activeTool === 'anchor' && (
                <button
                  type="button"
                  className="h-full w-full"
                  style={{ position: 'absolute', cursor: 'crosshair', zIndex: 5 }}
                  onClick={handleClick}
                />
              )}

              {/* Keeping this commented for now, but will look more into this bug for not being able to delete individual anchor points later on */}
              {activeTool === 'select' && (
                <button
                  type="button"
                  className="h-full w-full"
                  style={{ position: 'absolute', cursor: 'auto', zIndex: 5 }}
                  onMouseMove={handleMouseMove}
                  title={isShell ? '' : hexcode}
                />
              )}
            </TransformComponent>
          </TransformWrapper>

          <div className="absolute bottom-5 right-5 z-10">
            <Tooltip bodySlot="Delete asset" placement="top" isDark>
              <button
                type="button"
                onClick={() => setModalVisible(true)}
                className="flex w-10 h-10"
              >
                <Icon
                  name="Delete"
                  color="var(--eds-color-black)"
                  className="h-10 w-10 shadow-eds-elevation-3 rounded-xl px-2 py-2 bg-white"
                />
              </button>
            </Tooltip>
          </div>
        </div>
      )}
    </>
  );
}

export default EditorImage;
