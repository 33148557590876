/* eslint-disable jsx-a11y/label-has-associated-control */
import { useAppStore } from '../../state/store';

function PanelWeftWarp() {
  // TODO: update to course_px, wale_px, course_in, wale_in
  const coursePx = useAppStore(state => state.coursePx);
  const courseIn = useAppStore(state => state.courseIn);
  const walePx = useAppStore(state => state.walePx);
  const waleIn = useAppStore(state => state.waleIn);

  const setCoursePx = useAppStore(state => state.setCoursePx);
  const setCourseIn = useAppStore(state => state.setCourseIn);
  const setWalePx = useAppStore(state => state.setWalePx);
  const setWaleIn = useAppStore(state => state.setWaleIn);

  function handleCoursePxChange(event: React.ChangeEvent<HTMLInputElement>) {
    const inputVal = event.target.value;
    const decimalRegex = /\d*\.?\d{1,2}$/;

    if (decimalRegex.test(inputVal)) {
      setCoursePx(parseFloat(inputVal));
    }
  }

  return (
    <div className="flex flex-row justify-between ml-2">
      <div className="mr-4">
        <div className="mb-2">
          <label className="font-medium text-xs" htmlFor="weft">
            Course
          </label>
        </div>

        <input
          type="number"
          id="coursePx"
          name="coursePx"
          placeholder={`${coursePx} px`}
          onChange={event => setCoursePx(parseInt(event.target.value, 10))}
          className="w-28 mb-2 border border-eds-grey-3 border-radius rounded-md py-1 px-2 text-eds-grey-1"
        />

        <input
          type="number"
          id="courseIn"
          name="courseIn"
          inputMode="decimal"
          placeholder={`${courseIn} in`}
          onChange={event => setCourseIn(parseFloat(event.target.value))}
          className="w-28 border border-eds-grey-3 border-radius rounded-md py-1 px-2 text-eds-grey-1"
        />
      </div>

      <div>
        <div className="mb-2">
          <label className="font-medium text-xs" htmlFor="warp">
            Wale
          </label>
        </div>

        <input
          type="number"
          id="walePx"
          name="walePx"
          placeholder={`${walePx} px`}
          onChange={event => setWalePx(parseInt(event.target.value, 10))}
          className="w-28 mb-2 border border-eds-grey-3 border-radius rounded-md py-1 px-2 text-eds-grey-1"
        />

        <input
          type="number"
          id="waleIn"
          name="waleIn"
          placeholder={`${waleIn} in`}
          onChange={event => setWaleIn(parseInt(event.target.value, 10))}
          className="w-28 border border-eds-grey-3 border-radius rounded-md py-1 px-2 text-eds-grey-1"
        />
      </div>
    </div>
  );
}

export default PanelWeftWarp;
