import loadingGif from '@assets/spider.gif';

function LoadingAlgorithm() {
  return (
    <div className="flex flex-row justify-center w-full">
      <img src={loadingGif} alt="loading" />
    </div>
  );
}

export default LoadingAlgorithm;
