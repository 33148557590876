/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Dispatch, SetStateAction } from 'react';

type AnchorPointProps = {
  pos: { x: number; y: number };
  index: number;
  selectedIndex: number | null;
  setSelectedIndex: Dispatch<SetStateAction<number | null>>;
};

function AnchorPoint({ pos, index, selectedIndex, setSelectedIndex }: AnchorPointProps) {
  const divStyle = {
    cursor: 'pointer',
    display: 'flex',
    top: `${pos.y}%`,
    left: `${pos.x}%`,
    alignItems: 'center',
    transform: 'translate(-1px, -50%)',
    gap: '1px',
  };

  return (
    <div
      style={divStyle}
      className="absolute"
      onClick={() => {
        setSelectedIndex(index);
      }}
      role="button"
    >
      <div
        className={`h-0.5 w-0.5 ${
          selectedIndex === index ? 'bg-blue-600' : 'bg-white hover:bg-blue-100'
        } border-[1px] border-solid border-eds-blue-50 rounded-xl`}
      />

      <p className="font-medium" style={{ fontSize: 10, lineHeight: 1 }}>
        {index}
      </p>
    </div>
  );
}

export default AnchorPoint;
