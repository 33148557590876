import { Icon, Button } from '@nike/eds';
import { useState } from 'react';
import { useAppStore } from '../../state/store';
import ColorPicker from './ColorPicker';
import Graphic from './Graphic';
import PanelAnchor from './PanelAnchor';
import PanelWeftWarp from './PanelWeftWarp';

function AppPanel() {
  const bitmapColors = useAppStore(state => state.bitmap?.bitmapColors || []);
  const bitmap = useAppStore(state => state.bitmap);

  const setWedgeColors = useAppStore(state => state.setWedgeColors);
  const setExclusionColors = useAppStore(state => state.setExclusionColors);
  const setGraphicColors = useAppStore(state => state.setGraphicColors);
  const deleteBitmapAnchor = useAppStore(state => state.deleteBitmapAnchor);
  const deleteShellAnchor = useAppStore(state => state.deleteShellAnchor);

  const wedgeColors = bitmap?.wedgeColors || [];
  const exclusionColors = bitmap?.exclusionColors || [];
  const graphicColors = bitmap?.graphicColors || [];
  const allSelectedColors = [...wedgeColors, ...exclusionColors, ...graphicColors];
  const [renderTrigger, setRenderTrigger] = useState(false);
  const numberOfShellAnchors = useAppStore(state => state.shell?.anchors.length || 0);
  const numberOfBitmapAnchors = useAppStore(state => state.bitmap?.anchors.length || 0);

  const deleteAllAnchorPoints = () => {
    for (let i = 1; i <= numberOfShellAnchors; i += 1) {
      deleteShellAnchor(1);
    }
    for (let i = 0; i < numberOfBitmapAnchors; i += 1) {
      deleteBitmapAnchor(1);
    }
    setRenderTrigger(render => !render);
  };

  return (
    <section className="min-w-[300px] w-[300px] border-l border-eds-grey-2 overflow-y-auto">
      <div className="px-6 pt-8 pb-6 border-b border-solid border-eds-grey-2">
        <h3 className="text-sm font-medium mb-0 pl-0.5">Anchor Points</h3>
        <div className="pl-0">
          <h2 className="text-sm font-medium text-eds-grey-7 mb-4">
            <Icon name="AlertCircle" size="s" enableFocus className="mr-1" />
            Add points counterclockwise
          </h2>
        </div>
        <PanelAnchor />
        <Button
          onClick={() => {
            deleteAllAnchorPoints();
          }}
          variant="secondary"
          size="small"
          className="w-full mt-5 text-sm"
        >
          Delete All Points
        </Button>
      </div>

      <div className="px-6 py-6 border-b border-eds-grey-2">
        <h3 className="text-sm font-medium mb-4">Color Selection</h3>

        <div className="ml-2">
          <div className="mb-[20px]">
            <h4 className="font-medium text-xs mb-2">Negative Space</h4>

            <ColorPicker
              bitmapColors={bitmapColors}
              selectedColors={wedgeColors}
              setColors={setWedgeColors}
              allSelectedColors={allSelectedColors}
            />
          </div>

          <div className="mb-[20px]">
            <h4 className="font-medium text-xs mb-2">Outside Shape</h4>

            <ColorPicker
              bitmapColors={bitmapColors}
              selectedColors={exclusionColors}
              setColors={setExclusionColors}
              allSelectedColors={allSelectedColors}
            />
          </div>

          <div>
            <h4 className="font-medium text-xs mb-2">Graphic Base</h4>

            <ColorPicker
              bitmapColors={bitmapColors}
              selectedColors={graphicColors}
              setColors={setGraphicColors}
              allSelectedColors={allSelectedColors}
            />
          </div>
        </div>
      </div>

      <div className="px-6 py-6 border-b border-solid border-eds-grey-2">
        <h3 className="text-sm font-medium mb-4">Average Knit Ratio</h3>

        <PanelWeftWarp />
      </div>

      <div className="px-6 pt-6 pb-8">
        <h3 className="text-sm font-medium mb-6">Apply Graphic</h3>

        <Graphic />
      </div>
    </section>
  );
}

export default AppPanel;
