import { Icon, Tooltip } from '@nike/eds';
import { useState, useEffect, useRef } from 'react';
import { useAppStore } from '../../state/store';

function AppMenu() {
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef<HTMLElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const setNewSimulation = useAppStore(state => state.setNewSimulation);

  const handleRunNewSim = () => {
    setNewSimulation();
    setMenuOpen(false);
  };

  const handleOutsideClick = (event: MouseEvent) => {
    if (
      menuRef.current &&
      !menuRef.current.contains(event.target as Node) &&
      event.target !== buttonRef.current
    ) {
      setMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  return (
    <div className="flex flex-row z-20">
      <div className="mr-2">
        <Tooltip bodySlot="Menu" isDark>
          <button type="button" onClick={() => setMenuOpen(!menuOpen)}>
            <Icon name="Menu" size="m" color="white" />
          </button>
        </Tooltip>
      </div>

      <h1 className="font-bold ml-3 self-end">Spiderweb</h1>

      {menuOpen && (
        <menu
          ref={menuRef}
          className="absolute top-10 left-4 bg-white rounded-md shadow-eds-elevation-1 w-44 px-4 pt-1.5 pb-2 my-1"
        >
          <button
            type="button"
            onClick={handleRunNewSim}
            ref={buttonRef}
            className="hover:text-eds-grey-3 text-sm"
          >
            New Simulation
          </button>

          <a
            className="hover:text-eds-grey-3 text-sm"
            target="_blank"
            href="https://www.figma.com/proto/c5YXsAKxGJSGX691sPixuj/Spiderweb?page-id=1999%3A3113&node-id=3318-4714&viewport=3465%2C-829%2C0.5&t=SsoJHw8odMmx0NYB-1&scaling=scale-down-width&content-scaling=fixed&starting-point-node-id=3318%3A4714&hide-ui=1"
            rel="noreferrer"
          >
            Spiderweb Guide
          </a>
        </menu>
      )}
    </div>
  );
}

export default AppMenu;
