import EditorImage from './EditorImage';
import AppPanel from './AppPanel';
import LoadingAlgorithm from './AlgorithmLoading';
import { useAppStore } from '../../state/store';

function Editor() {
  const isSimulationRunning = useAppStore(state => state.isSimulationRunning);
  const shell = useAppStore(state => state.shell);
  const bitmap = useAppStore(state => state.bitmap);
  const setShell = useAppStore(state => state.setShell);
  const setBitmap = useAppStore(state => state.setBitmap);
  const removeShell = useAppStore(state => state.removeShell);
  const removeBitmap = useAppStore(state => state.removeBitmap);
  const addShellAnchor = useAppStore(state => state.addShellAnchor);
  const addBitmapAnchor = useAppStore(state => state.addBitmapAnchor);
  const deleteShellAnchor = useAppStore(state => state.deleteShellAnchor);
  const deleteBitmapAnchor = useAppStore(state => state.deleteBitmapAnchor);

  return (
    <section className="flex flex-row h-[calc(100vh-112px)] max-h-[calc(100vh-112px)]">
      {isSimulationRunning ? (
        <LoadingAlgorithm />
      ) : (
        <div className="flex flex-row justify-between w-full">
          <div className="w-full">
            <EditorImage
              imageType="shell"
              imageAsset={shell}
              setImageAsset={setShell}
              removeImageAsset={removeShell}
              addAnchor={addShellAnchor}
              deleteAnchor={deleteShellAnchor}
            />
          </div>

          <div>
            <div className="h-full w-2 border-x bg-eds-grey-4 border-eds-grey-2" />
          </div>

          <div className="w-full">
            <EditorImage
              imageType="bitmap"
              imageAsset={bitmap}
              setImageAsset={setBitmap}
              removeImageAsset={removeBitmap}
              addAnchor={addBitmapAnchor}
              deleteAnchor={deleteBitmapAnchor}
            />
          </div>
        </div>
      )}

      <AppPanel />
    </section>
  );
}

export default Editor;
