// This file can be replaced during build by using the `fileReplacements` array.
// When building for production, this file is replaced with `environment.prod.ts`.
import { SpiderwebEnvironment } from './environment';

export const environment: SpiderwebEnvironment = {
  production: false,
  composureEnvironment: 'test',
  clientId: 'nike.nxt.spiderweb',
  oktaIssuer: 'https://nike-qa.oktapreview.com/oauth2/ausa0mcornpZLi0C40h7',
  spiderwebScriptId: '70f8b82e-9565-4ae1-80e2-3029d64c4d43',
  svgToCurveScriptId: '45ec7aa9-8f23-44f7-b586-38a19a32d564',
};
