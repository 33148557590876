import { useAppStore } from '../../state/store';

function PanelAnchor() {
  const shellAnchorPoints = useAppStore(state => (state.shell ? state.shell.anchors.length : 0));
  const bitmapAnchorPoints = useAppStore(state => (state.bitmap ? state.bitmap.anchors.length : 0));

  return (
    <div className="flex gap-12 items-center">
      <div className="text-xs font-medium ml-2">
        <span>Shell: </span>
        <span>{shellAnchorPoints}</span>
      </div>

      <div className="text-xs font-medium">
        <span>Bitmap: </span>
        <span>{bitmapAnchorPoints}</span>
      </div>
    </div>
  );
}

export default PanelAnchor;
