import ReactGA from 'react-ga4';
import { Box } from '@nike/eds';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { Security, LoginCallback } from '@okta/okta-react';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { RequiresAuth } from '@nike.innovation/auth-guard';
import AppMain from '../app/core/components/Main';
import AppMenu from '../app/core/components/AppMenu';
import { oktaAuth } from './utils/oktaAuth';
import LoginError from './core/components/LoginError';
import Loading from './core/components/Loading';

import '../styles/globals.css';
import '@nike/eds/dist/index.css';

if (window.location.host === 'spiderweb.innovation.nike.io') {
  // you only want to initialize this in prod
  ReactGA.initialize('G-667LSHRMX1');
}

function App() {
  const navigate = useNavigate();
  const restoreOriginalUri = (_oktaAuth: unknown, originalUri: string) => {
    navigate(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <Box
        className="flex items-center eds-app-header eds--dark px-4 h-12"
        style={{ paddingLeft: '1rem', paddingRight: '1rem', height: '3rem' }}
      >
        <AppMenu />
      </Box>

      <Routes>
        <Route
          path="implicit/callback"
          element={<LoginCallback errorComponent={LoginError} loadingElement={<Loading />} />}
        />
        <Route path="" element={<RequiresAuth loadingElement={<Loading />} />}>
          <Route path="/" element={<AppMain />} />
        </Route>
      </Routes>
    </Security>
  );
}

export default App;
