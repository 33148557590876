/* eslint-disable @typescript-eslint/no-empty-function */
import { create } from 'zustand';

import { Action, Point, State, WithBitmapColors } from './api';
import { extractColors } from '../utils/bmp-utils';
import { ImageURL } from '../core/types';

export const useAppStore = create<State & Action>(set => ({
  shell: undefined,
  bitmap: undefined,
  graphic: undefined,
  coursePx: 300,
  courseIn: 10,
  walePx: 300,
  waleIn: 10,
  activeTool: 'select',
  isSimulationRunning: false,

  setShell: (shell: string, imageUrl: ImageURL, fileName: string) => {
    set({
      shell: {
        anchors: [],
        exportPoints: [],
        assetString: shell,
        url: imageUrl,
        fileName,
      },
    });
  },
  removeShell: () => set({ shell: undefined }),
  setBitmap: async (bitmap: string, bitmapUrl: ImageURL, fileName: string) => {
    const bitmapColors = await extractColors(bitmapUrl);

    set({
      bitmap: {
        anchors: [],
        exportPoints: [],
        assetString: bitmap,
        url: bitmapUrl,
        bitmapColors,
        wedgeColors: [],
        graphicColors: [],
        exclusionColors: [],
        fileName,
      },
    });
  },
  removeBitmap: () => set({ bitmap: undefined }),
  setGraphic: (graphic: string, graphicUrl: ImageURL) => {
    set({
      graphic: {
        assetString: graphic,
        url: graphicUrl,
      },
    });
  },
  removeGraphic: () => set({ graphic: undefined }),
  setCoursePx: (coursePx: State['coursePx']) => set({ coursePx }),
  setCourseIn: (courseIn: State['courseIn']) => set({ courseIn }),
  setWalePx: (walePx: State['walePx']) => set({ walePx }),
  setWaleIn: (waleIn: State['waleIn']) => set({ waleIn }),
  setActiveTool: (tool: State['activeTool']) => set({ activeTool: tool }),
  setIsSimulationRunning: (isRunning: State['isSimulationRunning']) =>
    set({ isSimulationRunning: isRunning }),

  addShellAnchor: (point: Point, exportPoint: Point) => {
    set(state => {
      if (state.shell) {
        return {
          shell: {
            ...state.shell,
            anchors: state.shell.anchors.concat(point),
            exportPoints: state.shell.exportPoints.concat(exportPoint),
          },
        };
      }

      return state;
    });
  },
  deleteShellAnchor: (selectedIndex: number) => {
    set(state => {
      if (state.shell) {
        return {
          shell: {
            ...state.shell,
            anchors: state.shell.anchors.filter((_anchor, index) => index !== selectedIndex - 1),
            exportPoints: state.shell.exportPoints.filter(
              (_point, index) => index !== selectedIndex - 1
            ),
          },
        };
      }

      return state;
    });
  },
  addBitmapAnchor: (point: Point, exportPoint: Point) => {
    set(state => {
      if (state.bitmap) {
        return {
          bitmap: {
            ...state.bitmap,
            anchors: state.bitmap.anchors.concat(point),
            exportPoints: state.bitmap.exportPoints.concat(exportPoint),
          },
        };
      }

      return state;
    });
  },
  deleteBitmapAnchor: (selectedIndex: number) => {
    set(state => {
      if (state.bitmap) {
        return {
          bitmap: {
            ...state.bitmap,
            anchors: state.bitmap.anchors.filter((_anchor, index) => index !== selectedIndex - 1),
            exportPoints: state.bitmap.exportPoints.filter(
              (_point, index) => index !== selectedIndex - 1
            ),
          },
        };
      }

      return state;
    });
  },
  setWedgeColors: (colors: WithBitmapColors['wedgeColors']) => {
    set(state => {
      if (state.bitmap) {
        return {
          bitmap: {
            ...state.bitmap,
            wedgeColors: colors,
          },
        };
      }

      return state;
    });
  },
  setGraphicColors: (colors: WithBitmapColors['graphicColors']) => {
    set(state => {
      if (state.bitmap) {
        return {
          bitmap: {
            ...state.bitmap,
            graphicColors: colors,
          },
        };
      }

      return state;
    });
  },
  setExclusionColors: (colors: WithBitmapColors['exclusionColors']) => {
    set(state => {
      if (state.bitmap) {
        return {
          bitmap: {
            ...state.bitmap,
            exclusionColors: colors,
          },
        };
      }

      return state;
    });
  },

  setNewSimulation: () => {
    set({
      shell: undefined,
      bitmap: undefined,
      graphic: undefined,
      coursePx: 300,
      courseIn: 10,
      walePx: 300,
      waleIn: 10,
      activeTool: 'select',
      isSimulationRunning: false,
    });
  },
}));
