import { Text } from '@nike/eds';
import { match } from 'ts-pattern';

const LoginError = ({ error }: { error: Error }) =>
  match(error.message)
    .with('User is not assigned to the client application.', () => (
      <Text>{error.message} Please request to join the AD group</Text>
    ))
    .otherwise(() => <Text>{error.message}</Text>);

export default LoginError;
