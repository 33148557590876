import { Spinner } from '@nike/eds';

function Loading() {
  return (
    <div className="flex align-center justify-center">
      <Spinner size="medium" className="m-2" />
    </div>
  );
}

export default Loading;
