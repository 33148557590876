import AppToolbar from './AppToolbar';
import Editor from './Editor';
import { SendPageView } from '../../utils/ga4-events';

function AppMain() {
  if (window.location.host === 'spiderweb.innovation.nike.io') {
    SendPageView({ page: window.location.pathname, title: 'Spiderweb' });
  }

  return (
    <main>
      <AppToolbar />
      <Editor />
    </main>
  );
}

export default AppMain;
