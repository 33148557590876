import ReactGA from 'react-ga4';
import { useEffect } from 'react';

export interface Ga4EventProps {
  category: 'shell' | 'bitmap' | 'simulation';
  action: 'set' | 'delete' | 'run' | 'result';
  label: 'success' | 'failure';
}

export function ga4Event(options: Ga4EventProps) {
  ReactGA.event(options);
}

export interface PageViewProps {
  page: string;
  title: 'Spiderweb';
}

export function SendPageView({ page, title }: PageViewProps) {
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page, title });
  }, []);
}
