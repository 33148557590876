/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/control-has-associated-label */
import { useState, useEffect, useRef } from 'react';
import { Tooltip } from '@nike/eds';

import AddIcon from './AddIcon';
import { useAppStore } from '../../state/store';
import ColorSwatch from '../components/ColorSwatch';

type ColorPickerProps = {
  bitmapColors: string[];
  selectedColors: string[];
  setColors: (colors: string[]) => void;
  allSelectedColors: string[];
};

function ColorPicker({
  bitmapColors,
  selectedColors,
  setColors,
  allSelectedColors,
}: ColorPickerProps) {
  const [colorMenu, setColorMenu] = useState(false);
  const menuRef = useRef<HTMLUListElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const hasBitmap = useAppStore(state => state.bitmap !== undefined);

  const addColor = (newColor: string) => {
    setColors([...selectedColors, newColor]);
  };
  const removeColor = (color: string) => {
    setColors(selectedColors.filter(c => c !== color));
  };

  const openMenu = () => {
    if (hasBitmap) {
      setColorMenu(true);
    }
  };

  const handleOutsideClick = (event: MouseEvent) => {
    if (
      menuRef.current &&
      !menuRef.current.contains(event.target as Node) &&
      event.target !== buttonRef.current
    ) {
      setColorMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  return (
    <div className="relative">
      <ul ref={menuRef} className="flex flex-wrap gap-y-3 gap-x-2">
        <li className="h-[24px]">
          <Tooltip bodySlot="Add color" isDark>
            <button type="button" onClick={openMenu}>
              <AddIcon />
            </button>
          </Tooltip>
        </li>

        {selectedColors.map(color => (
          <ColorSwatch key={color} rgb={color} handleClick={removeColor} cancel />
        ))}
      </ul>

      {colorMenu && hasBitmap && (
        <div className="w-[216px] absolute top-[30px] shadow-eds-elevation-2 bg-white z-20 rounded py-4 px-3">
          <h4 className="font-medium text-sm mb-4">Select Color</h4>

          <ul ref={menuRef} className="flex flex-wrap gap-y-3 gap-x-1">
            {bitmapColors.map(color => (
              <ColorSwatch
                key={color}
                rgb={color}
                handleClick={() => {
                  !allSelectedColors.includes(color) && addColor(color);
                }}
                isSelected={allSelectedColors.includes(color)}
              />
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default ColorPicker;
