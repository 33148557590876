/* eslint-disable react/require-default-props */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Icon } from '@nike/eds';

type ColorSwatchProps = {
  rgb: string;
  cancel?: boolean;
  handleClick: (color: string) => void;
  isSelected?: boolean;
};

function ColorSwatch({ rgb, handleClick, cancel = false, isSelected = false }: ColorSwatchProps) {
  const isWhite = rgb === 'rgb(255, 255, 255)';

  const modifiedRGB = rgb.replace(/[rgb()]/g, '');
  const [r, g, b] = modifiedRGB.split(',').map(Number);

  const convert2hex = (c: number) => {
    const hex = c.toString(16);
    return hex.length === 1 ? `0${hex}` : hex;
  };
  const renderIcon = () => {
    if (cancel) {
      return (
        <Icon name="Close" size="s" className="invisible group-hover:visible text-eds-grey-1" />
      );
    }

    if (!isSelected) {
      return (
        <Icon name="Check" size="s" className="invisible group-hover:visible text-eds-grey-1" />
      );
    }
  };

  return (
    <li
      id={rgb}
      onClick={() => handleClick(rgb)}
      title={`#${convert2hex(r)}${convert2hex(g)}${convert2hex(b)}`} // Add this line
      className={`group rounded-full w-[24px] h-[24px] ${
        (isWhite && !isSelected) || (isWhite && cancel)
          ? 'border border-solid border-eds-grey-1'
          : ''
      } ${
        !cancel && isSelected
          ? 'cursor-not-allowed border border-solid border-eds-black p-[1px]'
          : 'cursor-pointer'
      }`}
    >
      <div
        style={{ backgroundColor: rgb }}
        className={`rounded-full flex items-center justify-center ${
          !cancel && isSelected ? 'w-[20px] h-[20px]' : 'h-full'
        }`}
      >
        {renderIcon()}
      </div>
    </li>
  );
}

export default ColorSwatch;
