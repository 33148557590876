function AddIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12.4297" r="11.5" stroke="#707072" />
      <path
        d="M18 12.4297L6 12.4297M12 18.4297L12 6.42969"
        stroke="#707072"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
    </svg>
  );
}

export default AddIcon;
