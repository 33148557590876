import { ImageURL } from '../core/types';

type RgbColor = [red: number, green: number, blue: number];

function parseRgbColor(color: string): RgbColor | null {
  const match = color.match(/rgb\((\d+),\s*(\d+),\s*(\d+)\)/);
  if (match) {
    return [parseInt(match[1], 10), parseInt(match[2], 10), parseInt(match[3], 10)];
  }
  return null;
}

function colorDistanceToWhite(color: RgbColor): number {
  const [r, g, b] = color;
  const distanceSquared = (255 - r) ** 2 + (255 - g) ** 2 + (255 - b) ** 2;
  return Math.sqrt(distanceSquared);
}

function sortRgbColorsByProximityToWhite(colorsToSort: string[]): string[] {
  const parsedColors = colorsToSort
    .map(parseRgbColor)
    .filter((color): color is RgbColor => !!color);
  parsedColors.sort((a, b) => colorDistanceToWhite(b) - colorDistanceToWhite(a));
  return parsedColors.map(([r, g, b]) => `rgb(${r}, ${g}, ${b})`);
}

export async function extractColors(bitmapUrl: ImageURL): Promise<string[]> {
  const image = new Image();
  image.src = bitmapUrl as string;

  await image.decode();

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    console.error('Unable to get 2D context');
    return Promise.resolve([]);
  }

  canvas.width = image.width;
  canvas.height = image.height;

  ctx.drawImage(image, 0, 0);

  const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);

  const colors = new Set<string>();

  for (let i = 0; i < imageData.data.length; i += 4) {
    const r = imageData.data[i];
    const g = imageData.data[i + 1];
    const b = imageData.data[i + 2];
    const color = `rgb(${r}, ${g}, ${b})`;
    colors.add(color);
  }

  return Promise.resolve(sortRgbColorsByProximityToWhite(Array.from(colors)));
}
